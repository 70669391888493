import React from "react";
import Layout from "../layouts/page";

export default function About() {
  return (
    <Layout path="/about">
      <div className="row">
        <div className="col-12 col-md-6 p-5 pl-5">
          <img src="/about.png" className="img-fluid" alt="about" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-9 px-5 mb-50">
          <p>
            I'm a software engineer, public speaker, sometimes-tech blogger,
            retired open source developer, and privacy and security advocate
            based in Austin, TX.
          </p>
          <p>
            I have been coding since I was 12 and have 10+ years of professional
            coding and software development experience across a wide range of
            systems and products. I am a former award-winning open source
            developer and a holder of an MS in Cybersecurity.
          </p>
          <p>
            Last year, I moved from New York City to Austin to join Amazon's
            Privacy team where I am the tech lead of the Privacy Experience
            team.
          </p>
          <p>
            In addition to coding, my hobbies include hiking with my dog, live
            music show, cooking, and reading. I'm an amateur astronomer and
            barista, and I'm currently teaching myself native mobile
            development.
          </p>
        </div>
        <div className="col-12 col-md-3 mb-50">
          <img
            src="/me.jpg"
            className="img-fluid me"
            alt="danielle adams with raccoon"
          />
        </div>
      </div>
    </Layout>
  );
}
